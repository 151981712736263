<template>
  <div class="qesRank-comp" v-show="compStatus">
    <div class="qesRank-comp-title">{{ $t("member.Questionranking") }}</div>

    <section class="qesRank-comp-tophand">
      <img src="@/src/assets/member/tophand.png" alt="" />

      <div class="qesRank-comp-tophand-content">
        <label>Top 3</label>
        <p>{{ $t("member.outscorescompanyaverage") }}</p>
      </div>
    </section>

    <section class="qesRank-comp-chart" id="qesRank_comp_chart"></section>

    <!-- <section class="qesRank-comp-bottomhand">
      <div class="qesRank-comp-bottomhand-content">
        <label>Bottom 3</label>
        <img src="@/src/assets/member/bottomhand.png" alt="" />
      </div>

      <p>{{ $t("member.belowcompanyaverage") }}</p>
    </section> -->
    <section class="qesRank-comp-tophand bot">
      <div class="qesRank-comp-tophand-content">
        <label>Bottom 3</label>
        <p>{{ $t("member.belowcompanyaverage") }}</p>
      </div>
      <img src="@/src/assets/member/bottomhand.png" alt="" />
    </section>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import * as echarts from "echarts";
export default {
  props: {
    type: Number,
  },
  data() {
    return {
      compStatus: true,
      option: {
        tooltip: {
          show: true,
          confine: true,
          triggerOn: "click",
          // formatter: (val) => {
          //   return val.data.valueEn + "<br />" + val.data.valueZn;
          // },
        },
        grid: {
          containLabel: false,
          left: 0,
          right: 0,
        },
        xAxis: {
          type: "category",
          data: [],
          axisLabel: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: "rgba(60, 60, 60, 0.25)",
            },
          },
          axisTick: { show: false },
          splitLine: { show: false },
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: false,
          },
          axisLabel: { show: false },
          axisTick: { show: false },
          splitLine: { show: false },
        },
        series: [
          {
            type: "bar",
            barWidth: 20,
            label: {
              show: true,
              formatter: "{b} {value|{c}}",
              rich: { value: { color: "#6BAB8F", fontSize: 10 } },
              fontSize: 12,
              lineHeight: 18,
              position: "top",
            },
            data: [],
          },
        ],
      },
    };
  },
  watch: {
    type(val) {
      if (val) {
        this.pageInit();
      }
    },
  },
  mounted() {
    this.id = this.utils.decryption(this.$route.query.id);
    this.pageInit();
  },
  methods: {
    ...mapActions({
      qurank: "feedBack/qurank",
    }),
    async pageInit() {
      let params = {
        id: this.id,
        query: { deptType: this.type },
      };
      let res = await this.qurank(params);
      console.log(res);
      this.option.xAxis.data = [];
      this.option.series[0].data = [];
      if (
        res &&
        res.data &&
        res.data.highAvgQuestionList &&
        res.data.highAvgQuestionList.length > 0
      ) {
        res.data.highAvgQuestionList.forEach((val, e) => {
          this.option.xAxis.data.push(val.questionNum);

          let params = {
            value: val.avgScore,
            // valueZn: val.questionStem,
            // valueEn: val.questionStemEN,
            itemStyle: {
              color: "#6BAB8F",
              borderRadius: 32,
            },
          };

          this.option.series[0].data.push(params);
        });
      } else {
        this.compStatus = false
      }
      if (
        res &&
        res.data &&
        res.data.lowAvgQuestionList &&
        res.data.lowAvgQuestionList.length > 0
      ) {
        let lsAry1 = [],
          lsAry2 = [];
        res.data.lowAvgQuestionList.forEach((val, e) => {
          lsAry1.push(val.questionNum);

          let params = {
            value: -val.avgScore,
            // valueZn: val.questionStem,
            // valueEn: val.questionStemEN,
            itemStyle: {
              color: "#F8D37A",
              borderRadius: 32,
            },
          };

          lsAry2.push(params);
        });

        this.option.xAxis.data = this.option.xAxis.data.concat(lsAry1);
        this.option.series[0].data = this.option.series[0].data.concat(lsAry2);
      } else {
        this.compStatus = false
      }

      let chartDom = document.getElementById("qesRank_comp_chart");
      this.myChart = echarts.init(chartDom);
      this.myChart.setOption(this.option);

      window.addEventListener("resize", () => {
        this.myChart.setOption(this.option, true);
        this.myChart.resize();
      });
    },
  },
  created() {},
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";

@media screen and (max-width: 760px) {
  .qesRank-comp {
    width: 100%;
    padding: toMRem(20) 0 0 0;
  }
  .qesRank-comp-title {
    width: 100%;
    font-size: toMRem(14);
  }
  .qesRank-comp-tophand {
    display: flex;
    width: 100%;
    margin: toMRem(18) 0 0 0;
    align-items: center;

    img {
      width: toMRem(22);
      height: auto;
      flex-shrink: 0;
    }
  }
  .qesRank-comp-tophand-content {
    flex-basis: 100%;
    //padding-left: toMRem(10);

    label {
      display: block;
      width: 100%;
      opacity: 0.8;
    }
    p {
      opacity: 0.6;
      line-height: 1.4;
      font-size: toMRem(12);
      padding: toMRem(6) 0 0 0;
    }
  }

  .qesRank-comp-bottomhand {
    width: 100%;

    .qesRank-comp-bottomhand-content {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-end;

      img {
        width: toMRem(22);
        height: auto;
        flex-shrink: 0;
      }
      label {
        display: inline-block;
        opacity: 0.8;
        padding-left: toMRem(10);
      }
    }
    p {
      //width: 100%;
      opacity: 0.6;
      line-height: 1.4;
      font-size: toMRem(12);
      padding: toMRem(6) 0 0 0;
      text-align: right;
    }
  }
  .qesRank-comp-chart {
    width: 100%;
    height: toMRem(200);
  }
}

@media screen and (min-width: 761px) {
  .qesRank-comp {
    position: relative;
    width: 100%;
    padding: toPad(16) 0 0 0;
  }
  .qesRank-comp-title {
    width: 100%;
    font-size: toPad(14);
  }
  .qesRank-comp-tophand {
    display: flex;
    //width: 100%;
    float: right;
    margin: toPad(18) 0 0 0;
    align-items: center;

    img {
      width: toPad(22);
      height: auto;
      flex-shrink: 0;
    }
    &.bot {
      position: absolute;
      bottom: toPad(10);
      float: left;
      img {
        margin-left: toPad(8);
      }
      label {
        text-align: right;
      }
    }
  }
  .qesRank-comp-tophand-content {
    flex-basis: 100%;
    //padding-left: toPad(10);

    label {
      display: block;
      width: 100%;
      opacity: 0.8;
    }
    p {
      opacity: 0.6;
      line-height: 1.4;
      font-size: toPad(12);
      padding: toPad(6) 0 0 0;
    }
  }

  .qesRank-comp-bottomhand {
    width: 100%;
    float: left;
    .qesRank-comp-bottomhand-content {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-start;

      img {
        width: toPad(22);
        height: auto;
        flex-shrink: 0;
      }
      label {
        display: inline-block;
        opacity: 0.8;
        padding-left: toPad(10);
      }
    }
    p {
      //width: 100%;
      opacity: 0.6;
      line-height: 1.4;
      font-size: toPad(12);
      padding: toPad(6) 0 0 0;
      //text-align: right;
    }
  }
  .qesRank-comp-chart {
    width: 100%;
    height: toPad(250);
  }
}
</style>

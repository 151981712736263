<!--
 * @Author: Eric hongyong.yang@nttdata.com
 * @Date: 2023-02-15 18:18:30
 * @LastEditors: Eric hongyong.yang@nttdata.com
 * @LastEditTime: 2023-04-18 18:36:28
 * @FilePath: \edp-web\src\components\member\components\compareBar.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <section class="departWe-detail-rankProcess">
    <div
      class="departWe-detail-rankProcess-line"
      :class="{
        'depart-bg-lineGreen': bgColor === 'green',
        'depart-bg-lineBlue': bgColor === 'blue',
        'depart-bg-lineYellow': bgColor === 'yellow',
      }"
    >
      <span class="departWe-rg-no" :style="{ left: leftStyle }">
        <span class="departWe-rg-word fb f18">NO.{{ ranking }}</span>

        <i class="el-icon-caret-bottom departWe-rg-icon"></i>

        <span class="departWe-rg-line"></span>
      </span>
    </div>

    <span class="departWe-detail-rankProcess-word dd-sp1">1</span>
    <span class="departWe-detail-rankProcess-word dd-sp2">{{ userTotal }}</span>
  </section>
</template>
<script>
export default {
  name: "customModal",
  props: {
    ranking: Number,
    userTotal: Number,
    bgColor: String,
    leftStyle: String,
  },
  data() {
    return {
      // leftStyle: 0,
    };
  },
  mounted() {
    // console.log(this.props.beatsPercentage);
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "@/src/styles/variable.scss";
.departWe-detail-rankProcess {
  margin-bottom: toPad(36);
}
.departWe-detail-rankProcess {
  position: relative;
  width: 100%;
  padding-top: toPad(42);

  .departWe-detail-rankProcess-line {
    clear: both;
    position: relative;
    width: 100%;
    height: toPad(18);
    border-radius: toPad(52);
  }
  .departWe-detail-rankProcess-word {
    display: inline-block;
    padding-top: toPad(10);
    font-size: toPad(14);
    opacity: 0.6;

    &.dd-sp1 {
      float: left;
    }
    &.dd-sp2 {
      float: right;
    }
  }
  .departWe-detail-rankProcess-bottom {
    clear: both;
    width: 100%;
    padding-top: toPad(10);
  }
}
.depart-bg-lineGreen {
  background: linear-gradient(90deg, #96e0d3 0%, #75c6b2 100%);
}
.depart-bg-lineBlue {
  background: linear-gradient(90deg, #96c8e0 0%, #78c1e6 100%);
}
.depart-yellow1 {
  color: #ebb938;
}
.depart-bg-lineYellow {
  background: linear-gradient(90deg, #fad882 0%, #ffd66c 100%);
}
.departWe-rg-no {
  display: inline-block;
  position: absolute;
  width: 1px;
  bottom: 6px;
  left: 0;
  z-index: 1;
  text-align: center;

  .departWe-rg-word {
    clear: both;
    position: relative;
    display: inline-block;
    white-space: nowrap;
    float: left;
    transform: translateX(-50%);
  }
  .departWe-rg-icon {
    clear: both;
    position: relative;
    display: inline-block;
    color: #76c7b4;
    float: left;
    transform: translateX(-50%);
  }
  .departWe-rg-line {
    clear: both;
    position: relative;
    float: left;
    display: inline-block;
    width: 1px;
    height: toPad(18);
    overflow: hidden;
    border-left: 1px dashed #fff;
    transform: translateX(-50%);
  }
}
</style>

<template>
  <div class="customSelect">
    <div class="currentLabel" @click="visible = !visible">
      <span>{{ currentLabel }}</span>
      <i class="icon" :class="{ 'icon-down': visible }"></i>
    </div>
    <ul class="option" :class="{ 'option-block': visible }">
      <li
        v-for="(item, index) in option"
        :key="index"
        class="hover"
        @click="handleInput(index)"
      >
        {{ item.label }}
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "customSelect",
  props: ["value", "option"],
  data: function () {
    return {
      currentLabel: null,
      visible: false,
    };
  },
  mounted() {
    this.currentLabel = this.option[this.value].label;
  },
  methods: {
    handleInput(index) {
      var value = this.option[index].value;
      this.currentLabel = this.option[index].label;
      this.$emit("input", value);
      this.$emit("change");
      this.visible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/src/styles/variable.scss";
@media screen and (max-width: 760px) {
  .customSelect {
    width: 100%;
    position: relative;
  }
  .currentLabel {
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      text-align: center;
      display: inline-block;
      width: toMRem(260);
      height: toMRem(36);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .icon {
      display: block;
      width: 0;
      height: 0;
      border-left: toMRem(8) solid transparent;
      border-right: toMRem(8) solid transparent;
      border-top: toMRem(12) solid #000;
      transition: all 0.2s ease-in-out;
      overflow: hidden;
    }
    .icon-down {
      transform: rotate(-180deg);
    }
  }
  .option {
    width: toMRem(339);
    position: absolute;
    top: toMRem(40);
    z-index: 2;
    background: #fff;
    list-style: none;
    margin: 0;
    box-sizing: border-box;
    border-radius: toMRem(10);
    box-shadow: 0 2px 12px 0 #ddd;
    height: 0;
    transition: all 0.2s ease-in-out;
    overflow: hidden;
    left: 50%;
    transform: translateX(-50%);
    li {
      font-size: toMRem(14);
      padding: 0 toMRem(20);
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #606266;
      line-height: toMRem(36);
      box-sizing: border-box;
      cursor: pointer;
    }
    li:hover {
      background-color: #f5f7fa;
    }
  }
  .option-block {
    height: toMRem(200);
  }
}
@media screen and (min-width: 760px) {
  .customSelect {
    width: 100%;
    position: relative;
  }
  .currentLabel {
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      text-align: center;
      display: inline-block;
      width: toPad(260);
      height: toPad(36);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .icon {
      display: block;
      width: 0;
      height: 0;
      border-left: toPad(8) solid transparent;
      border-right: toPad(8) solid transparent;
      border-top: toPad(12) solid #000;
      transition: all 0.2s ease-in-out;
    }
    .icon-down {
      transform: rotate(-180deg);
    }
  }
  .option {
    width: toPad(339);
    position: absolute;
    top: toPad(40);
    z-index: 2;
    background: #fff;
    list-style: none;
    margin: 0;
    box-sizing: border-box;
    border-radius: toPad(10);
    box-shadow: 0 2px 12px 0 #ddd;
    height: 0;
    transition: all 0.2s ease-in-out;
    overflow: hidden;
    left: 50%;
    transform: translateX(-50%);
    li {
      font-size: toPad(14);
      padding: 0 toPad(20);
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #606266;
      line-height: toPad(36);
      box-sizing: border-box;
      cursor: pointer;
    }
    li:hover {
      background-color: #f5f7fa;
    }
  }
  .option-block {
    height: toPad(200);
  }
}
</style>

<template>
  <div>
    <edp-header
      class="bf"
      :headerLeftWord="$t('member.360Header')"
    ></edp-header>
    <div class="individual-wrap">
      <!-- I received -->
      <div class="row receivedAndGave">
        <div class="chart-wrap mb16">
          <p class="chart-tit f16 fb mb10">
            {{ $t("member.Receivedcomments") }}
            <!-- <el-tooltip
              class="item"
              effect="dark"
              placement="right"
              popper-class="draw_share_atooltip"
            >
              <div
                v-html="ToBreak($t('member.ReceivedTips'))"
                slot="content"
              ></div>
              <img src="@/src/assets/member/tips.png" alt="" class="tips"
            /></el-tooltip> -->
          </p>
          <div class="fjsba">
            <div class="round-wrap fjsb">
              <div class="round-one round-item tac">
                <span class="round-num tac f34 bggreen-1 mb10">
                  {{ received.inviteAssessFromNum || 0 }} </span
                ><br />
                <span class="round-text f14">{{ $t("member.wReceived") }}</span>
              </div>
              <div class="split-line"></div>
              <div class="round-two round-item">
                <span class="round-num tac f24 bgyellow-1 mb10">{{
                  received.inviteNum || 0
                }}</span
                ><br />
                <p class="round-text f14">
                  {{ $t("member.Sendoutratinginvitation") }}<br />
                  <span class="cg1"
                    >{{ received.inviteReplyPercentage || 0 }}%</span
                  >
                </p>
              </div>
            </div>
            <div class="number-wrap">
              <p class="number-item mb8 f14">
                {{ $t("member.Activelyreceivedscores") }}
                <span class="f32 cg">{{
                  received.activeAssessFromNum || 0
                }}</span>
              </p>
              <p class="number-item mb8 f14">
                {{ $t("member.Totalscorereceived") }}
                <span class="f32 cg1">{{ received.assessFromNum || 0 }}</span>
              </p>
              <template>
                <p class="number-item mb8 f14">
                  {{ $t("member.Ratingsreceived") }}
                </p>
                <compareBar
                  :ranking="parseInt(received.ranking)"
                  :userTotal="received.userTotal"
                  :bgColor="'green'"
                  :leftStyle="received.leftStyle"
                ></compareBar>
                <p class="number-item f16">
                  {{ $t("member.beating") }}
                  <span class="f18 cg">{{ received.beatsPercentage }}% </span>
                  {{ $t("member.ofemployees") }}
                </p>
              </template>
            </div>
          </div>
        </div>

        <!-- I gave -->
        <div class="chart-wrap mb16">
          <p class="chart-tit f16 fb mb10">
            {{ $t("member.Commentstoothers") }}
            <!-- <el-tooltip
              class="item"
              effect="dark"
              placement="right"
              popper-class="draw_share_atooltip"
            >
              <div
                v-html="ToBreak($t('member.givedTips'))"
                slot="content"
              ></div>
              <img src="@/src/assets/member/tips.png" alt="" class="tips"
            /></el-tooltip> -->
          </p>
          <div class="fjsba">
            <div class="round-wrap fjsb">
              <div class="round-one round-item tac">
                <span class="round-num tac f36 bgblue-1 mb10">
                  {{ gave.inviteReplyFromNum || 0 }} </span
                ><br />
                <span class="round-text mb16 f14">
                  {{ $t("member.Scoregiven") }}</span
                >
              </div>
              <div class="split-line"></div>
              <div class="round-two round-item">
                <span class="round-num tac f24 bgyellow-1 mb10">
                  {{ gave.inviteFromNum || 0 }} </span
                ><br />
                <span class="round-text f14"
                  >{{ $t("member.Receivedratinginvitation") }}<br />
                  <span class="cg1"
                    >{{ gave.assessReplyPercentage }}%</span
                  ></span
                >
              </div>
            </div>
            <div class="number-wrap">
              <p class="number-item mb8 f14">
                {{ $t("member.Activelyscoregiven") }}
                <span class="f32 c55">{{ gave.activeAssessNum || 0 }}</span>
              </p>
              <p class="number-item mb8 f14">
                {{ $t("member.Totalscoregiven") }}
                <span class="f32 c66">{{ gave.assessNum || 0 }}</span>
              </p>
              <template>
                <p class="number-item mb8 f14">
                  {{ $t("member.Rankingratingothers") }}
                </p>
                <compareBar
                  :ranking="parseInt(gave.ranking)"
                  :userTotal="gave.userTotal"
                  :bgColor="'blue'"
                  :leftStyle="gave.leftStyle"
                ></compareBar>
                <p class="number-item f16">
                  {{ $t("member.beating") }}
                  <span class="f18 c45">{{ gave.beatsPercentage }}%</span>
                  {{ $t("member.ofemployees") }}
                </p>
              </template>
            </div>
          </div>
        </div>
      </div>
      <!-- score -->
      <div class="row">
        <div class="col-8">
          <div class="chart-wrap mb16">
            <p class="chart-tit f16 fb mb10">
              {{ $t("member.Score") }}
              <!-- <el-tooltip
                class="item"
                effect="dark"
                placement="right"
                popper-class="draw_share_atooltip"
              >
                <div
                  v-html="ToBreak($t('member.scoreTips'))"
                  slot="content"
                ></div>
                <img src="@/src/assets/member/tips.png" alt="" class="tips"
              /></el-tooltip> -->
            </p>
            <div>
              <div class="select-wrap tac mb10 pot">
                <customSelect
                  v-model="diagramValue"
                  @change="getValue"
                  :option="options"
                ></customSelect>
              </div>
              <div class="score-title fjsb">
                <div class="title-item fcjsa">
                  <p class="f12 tac">{{ chartData[0].name }}</p>
                  <p class="f22 cg2">
                    {{ chartData[0].avgSumScore }}
                    <span class="f14">({{ chartData[0].userTotal }})</span>
                  </p>
                </div>
                <div class="score-line"></div>
                <div class="title-item fcjsa">
                  <p class="f12 tac">{{ chartData[1].name }}</p>
                  <p class="f22 cy">
                    {{ chartData[1].avgSumScore }}
                    <span class="f14">({{ chartData[1].userTotal }})</span>
                  </p>
                </div>
              </div>
              <div class="chart-wraps">
                <div
                  class="score-chart-wrap"
                  :class="[`radar-${diagramValue}`]"
                />
              </div>
            </div>
          </div>
          <p class="report-tips f14">
            <img src="@/src/assets/member/tips.png" alt="" class="tips" />
            {{ $t("member.Datareporttime") }}
          </p>
        </div>
        <div class="col-16">
          <!-- rank -->
          <template>
            <div class="chart-wrap mb16">
              <p class="chart-tit f16 fb mb10">
                {{ $t("member.Ranking") }}
                <!-- <el-tooltip
                  class="item"
                  effect="dark"
                  placement="right"
                  popper-class="draw_share_atooltip"
                >
                  <div
                    v-html="ToBreak($t('member.scoreTips'))"
                    slot="content"
                  ></div>
                  <img src="@/src/assets/member/tips.png" alt="" class="tips"
                /></el-tooltip> -->
              </p>
              <div class="row">
                <div class="col-12">
                  <div class="rank-title fjsb">
                    <div class="fcjsa">
                      <div class="f14 fac">{{ $t("member.Mytotalscore") }}</div>
                      <!-- <span class="f12">{{ rank.depNameCN }}</span> -->
                    </div>
                    <span class="f26 cg3">{{ rank.totalAvgScore || 0 }}</span>
                  </div>
                  <div class="rank-title fjsb mb10">
                    <div class="rank-title-item fcjsb">
                      <div class="fjsb">
                        <div class="fcjsa top-text-left">
                          <span class="f10">{{ rank.depNameCN }}</span>
                          <span class="f10">
                            {{ $t("member.Departmentaveragescore") }}</span
                          >
                        </div>
                        <span class="f20 cg3 top-text-right tar">{{
                          rank.depAvg || 0
                        }}</span>
                      </div>
                      <div class="fjsb">
                        <span class="f10 bot-text-left">{{
                          $t("member.Departmentranking")
                        }}</span>
                        <span class="f16 fb tar bot-text-right tar">
                          {{ rank.depRank || 0 }}
                        </span>
                      </div>
                    </div>
                    <div class="rank-title-line"></div>
                    <div class="rank-title-item fcjsb">
                      <div class="fjsb">
                        <span class="f10 top-text-left">{{
                          $t("member.Companyaveragescore")
                        }}</span>
                        <span class="f22 cy top-text-right tar">{{
                          rank.enterpriseAvg || 0
                        }}</span>
                      </div>
                      <div class="fjsb">
                        <span class="f10 bot-text-left">{{
                          $t("member.Thecompanyranked")
                        }}</span>
                        <span class="f16 fb tar bot-text-right tar">
                          {{ rank.enterpriseRank || 0 }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="rank-beats mb8">
                    <div class="deats-wrap">
                      <compareBar
                        :ranking="parseInt(rank.compareBarRank) || 0"
                        :userTotal="rank.compareBarTotal"
                        :bgColor="'yellow'"
                        :leftStyle="rank.leftStyle"
                      ></compareBar>
                      <p class="f12">
                        <!-- {{ $t("member.Thecompanyranked") }}
                        <span class="cg3"> {{ rank.compareBarRank }} </span>, -->
                        {{ $t("member.beating") }}
                        <span class="cfb"> {{ rank.percentage }}% </span>
                        {{ $t("member.ofemployees") }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="rank-beats-tit">
                    <div class="rank-select-wrap f12">
                      <div
                        class="rank-select-item"
                        :class="{ active: tabIndex === 1 }"
                        @click="switchTab(1)"
                      >
                        Business Unit
                      </div>
                      <div
                        class="rank-select-item"
                        :class="{ active: tabIndex === 2 }"
                        @click="switchTab(2)"
                      >
                        EVP
                      </div>
                      <!-- <el-radio-group
                        class="rank-select"
                        v-model="radio1"
                        size="mini"
                      >
                        <el-radio-button :label="1"
                          >Business Unit</el-radio-button
                        >
                        <el-radio-button :label="2"> EVP</el-radio-button>
                      </el-radio-group> -->
                    </div>
                  </div>
                  <qrq :type="tabIndex" />
                </div>
              </div>
            </div>
          </template>

          <div class="commonts-wrap">
            <div class="chart-wrap">
              <p class="chart-tit f16 fb mb10">
                {{ $t("member.Comment") }}
                <!-- <el-tooltip
                  class="item"
                  effect="dark"
                  placement="right"
                  popper-class="draw_share_atooltip"
                >
                  <div
                    v-html="ToBreak($t('member.commentsTips'))"
                    slot="content"
                  ></div>
                  <img src="@/src/assets/member/tips.png" alt="" class="tips"
                /></el-tooltip> -->
              </p>
              <div class="item-wrap fjsba">
                <div
                  class="item"
                  v-for="(item, index) in comments"
                  :key="item.id"
                >
                  <div>
                    <h2 class="mb10">{{ item.questionNum }}</h2>
                    <p>{{ item.questionStemEN }}</p>
                  </div>
                  <div class="right" :class="{ bgGreen: index == 0 }">
                    {{ item.commentsNum }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import compareBar from "@/src/components/feedback/compareBar";
import customSelect from "@/src/components/feedback/customSelect";
import qrq from "@/src/components/feedback/questionRank.vue";
export default {
  components: {
    compareBar,
    customSelect,
    qrq,
  },
  data() {
    return {
      rank: {},
      received: {
        inviteNum: 0,
        inviteAssessFromNum: 0,
        inviteReplyPercentage: 0,
        activeAssessFromNum: 0,
        assessFromNum: 0,
        ranking: 0,
        userTotal: 0,
        beatsPercentage: 0,
      },
      gave: {
        inviteFromNum: 0,
        inviteReplyFromNum: 0,
        assessReplyPercentage: 0,
        activeAssessNum: 0,
        assessNum: 0,
        ranking: 0,
        userTotal: 0,
        beatsPercentage: 0,
      },
      chartData: [
        {
          name: "",
          value: [],
          avgSumScore: 0,
          userTotal: 0,
        },
        {
          name: "",
          value: [],
          avgSumScore: 0,
          userTotal: 0,
        },
      ],
      comments: [],
      options: [
        {
          value: 0,
          label: `${this.$t("member.Selfscore")} ${this.$t(
            "member.&"
          )} ${this.$t("member.Myaveragescore")}`,
          labelArr: [
            this.$t("member.Selfscore"),
            this.$t("member.Myaveragescore"),
          ],
        },
        {
          value: 1,
          label: `${this.$t("member.Seniorsscore")} ${this.$t(
            "member.&"
          )} ${this.$t("member.Othersscore")}`,
          labelArr: [
            this.$t("member.Seniorsscore"),
            this.$t("member.Othersscore"),
          ],
        },
        {
          value: 2,
          label: `${this.$t("member.Myaveragescore")} ${this.$t(
            "member.&"
          )} ${this.$t("member.EVPdepartmentave")}`,
          labelArr: [
            this.$t("member.Myaveragescore"),
            this.$t("member.EVPdepartmentave"),
          ],
        },
        {
          value: 3,
          label: `${this.$t("member.FromEVPdepartment")} ${this.$t(
            "member.&"
          )} ${this.$t("member.FromEVPexternal")}`,
          labelArr: [
            this.$t("member.FromEVPdepartment"),
            this.$t("member.FromEVPexternal"),
          ],
        },
        {
          value: 4,
          label: `${this.$t("member.Invitation")} ${this.$t(
            "member.&"
          )} ${this.$t("member.Initiative")}`,
          labelArr: [
            this.$t("member.Invitation"),
            this.$t("member.Initiative"),
          ],
        },
      ],
      diagramValue: 0,
      scoreChart: null,
      radio1: 1,
      emptyArr: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      tabIndex: 1,
    };
  },
  mounted() {
    this.id = this.utils.decryption(this.$route.query.id);
    this.getScore();
    this.getReceived();
    this.getGive();
    this.getDiagram();
    this.getComments();
  },
  methods: {
    ...mapActions({
      empScore: "feedBack/empScore",
      receiveds: "feedBack/received",
      gives: "feedBack/give",
      diagram: "feedBack/diagram",
      comment: "feedBack/comments",
    }),
    switchTab(val) {
      this.tabIndex = val;
    },
    ToBreak(val) {
      return val.replaceAll("。", "<br />");
    },
    async getScore() {
      let res = await this.empScore(this.id);
      this.rank = res.data;
      this.rank = {
        ...this.rank,
        compareBarRank: res.data.enterpriseRank || 0,
        percentage: res.data.beatsEnterprisePercentage,
        compareBarTotal: res.data.enterpriseUserTotal,
        leftStyle: 100 - parseInt(res.data.beatsEnterprisePercentage) + "%",
      };
    },
    async getReceived() {
      let res = await this.receiveds(this.id);
      this.received = res.data;
      this.received = {
        ...this.received,
        leftStyle: 100 - parseInt(res.data.beatsPercentage) + "%",
      };
    },
    async getGive() {
      let res = await this.gives(this.id);
      this.gave = res.data;
      this.gave = {
        ...this.gave,
        leftStyle: 100 - parseInt(res.data.beatsPercentage) + "%",
      };
    },
    getValue() {
      this.getDiagram();
    },
    async getDiagram() {
      let index = this.diagramValue;
      let params = { id: this.id, query: { type: this.diagramValue + 1 } };
      let res = await this.diagram(params);
      let datas = res.data;

      let chartData = [];

      chartData[0] = {
        name: this.options[index].labelArr[0],
        value: datas.left.scoreAvgArr || this.emptyArr,
        avgSumScore: datas.left.totalAvg || 0,
        userTotal: datas.left.totalNum || 0,
      };

      chartData[1] = {
        name: this.options[index].labelArr[1],
        value: datas.right.scoreAvgArr || this.emptyArr,
        avgSumScore: datas.right.totalAvg || 0,
        userTotal: datas.right.totalNum || 0,
      };

      console.log(chartData);
      this.chartData = chartData;
      this.drawScoreChart();
    },
    drawScoreChart(index) {
      let option = {
        title: {
          text: "",
        },
        color: ["#58AD9E", "#E9C379"],
        legend: {
          icon: "circle",
          bottom: 0,
          itemWidth: 10,
          itemHeight: 10,
          itemStyle: {
            borderCap: "round",
            borderRadius: 10,
          },
        },
        tooltip: {
          show: true,
        },
        radar: [
          {
            indicator: [
              { name: "Q1", max: 10 },
              { name: "Q2", max: 10 },
              { name: "Q3", max: 10 },
              { name: "Q4", max: 10 },
              { name: "Q5", max: 10 },
              { name: "Q6", max: 10 },
              { name: "Q7", max: 10 },
              { name: "Q8", max: 10 },
              { name: "Q9", max: 10 },
              { name: "Q10", max: 10 },
            ],
            center: ["50%", "47%"],
            radius: "60%",
            startAngle: 90,
            splitNumber: 4,
            shape: "circle",
            axisName: {
              color: "#050505",
            },
            splitArea: {
              areaStyle: {
                color: ["#fff"],
              },
            },
            axisLine: {
              lineStyle: {
                color: "#e0e0e0",
              },
            },
            splitLine: {
              lineStyle: {
                color: "#e0e0e0",
              },
            },
          },
        ],
        series: [
          {
            type: "radar",
            emphasis: {
              lineStyle: {
                width: 4,
              },
            },
            data: [
              {
                value: this.chartData[0].value,
                name: this.chartData[0].name,
                // symbol: "rect",
                symbolSize: 1,
                areaStyle: {
                  color: "rgba(150, 201, 170, 0.74)",
                },
              },
              {
                value: this.chartData[1].value,
                name: this.chartData[1].name,
                // symbol: "rect",
                symbolSize: 1,
                areaStyle: {
                  color: "rgba(232, 193, 117, 0.7)",
                },
              },
            ],
          },
        ],
      };
      this.scoreChart = echarts.init(
        this.$el.querySelector(`.radar-${index ?? this.diagramValue}`)
      );
      this.scoreChart.setOption(option);
      window.addEventListener("resize", () => {
        this.scoreChart.resize();
      });
    },
    async getComments() {
      let res = await this.comment(this.id);
      this.comments = res.data;
    },
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";
@media screen and (max-width: 760px) {
  .individual-wrap {
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding: toMRem(80) toMRem(18) toMRem(100);
    margin-top: toMRem(20);
    background: #f7f7f7;
    .chart-wrap {
      position: relative;
      width: toMRem(339);
      //   height: toMRem(46);
      padding: toMRem(16) toMRem(18);
      background: #ffffff;
      box-shadow: 0px 4px 9px rgba(209, 209, 209, 0.25);
      border-radius: toMRem(16);
      .round-wrap {
        align-items: center;
        height: toMRem(136);
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        .round-one {
          position: relative;
          bottom: toMRem(20);
          width: 50%;
          .round-text {
            //margin-right: toMRem(14);
            display: inline-block;
            text-align: right;
          }
          .round-num {
            display: inline-block;
            width: toMRem(58);
            height: toMRem(58);
            line-height: toMRem(58);
            border-radius: 50%;
            color: #ffffff;
          }
        }
        .round-two {
          display: flex;
          position: relative;
          top: toMRem(20);
          vertical-align: top;
          width: 50%;
          .round-text {
            display: inline-block;
            text-align: left;
          }
          .round-num {
            display: inline-block;
            width: toMRem(42);
            height: toMRem(42);
            line-height: toMRem(42);
            margin-right: toMRem(14);
            border-radius: 50%;
            color: #ffffff;
          }
        }
        .split-line {
          width: 1px;
          height: toMRem(60);
          mix-blend-mode: multiply;
          background: rgba(0, 0, 0, 0.2);
          transform: rotate(30deg);
        }
      }
      .number-wrap {
        .number-item {
          display: flex;
          align-items: center;
          span {
            margin-left: toMRem(10);
          }
        }
      }
      .select-wrap {
        height: toMRem(60);
        .sel {
          width: 100%;
          height: 100%;
          color: #000;
        }
      }
      .score-header {
        margin-bottom: toMRem(10);
      }
      .score-title {
        width: 100%;
        .title-item {
          width: 50%;
          padding: 0 toMRem(4);
          align-items: center;
        }
        .score-line {
          width: 1px;
          height: toMRem(34);
          background: rgba(0, 0, 0, 0.2);
        }
      }
      .chart-wraps {
        position: relative;
        .questionWordList-icon {
          z-index: 1;
          position: absolute;
          right: toMRem(10);
          top: toMRem(20);
          color: #439e69;
          font-size: toMRem(18);
          cursor: pointer;
        }
        .score-chart-wrap {
          position: relative;
          width: 100%;
          height: toMRem(400);
          box-sizing: content-box;
          margin: 0 auto;
          padding: 0 0 toRem(10) 0;
        }
      }
      .rank-title {
        padding: toMRem(12) toMRem(4);
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);
        .rank-title-item {
          width: 47%;
          height: toMRem(90);
          // span {
          //   width: 50%;
          // }
          .top-text-left {
            width: 65%;
          }
          .top-text-right {
            width: 35%;
          }
          .bot-text-left {
            width: 75%;
          }
          .bot-text-right {
            width: 25%;
          }
        }
        .rank-title-line {
          width: 1px;
          height: toMRem(84);
          background: rgba(0, 0, 0, 0.2);
        }
        .fair-img {
          img {
            position: relative;
            bottom: toMRem(6);
            display: inline-block;
            width: toMRem(16);
            height: toMRem(16);
            margin: 0 toMRem(10);
          }
        }
        .fair-content {
          width: toMRem(220);
          left: 150%;
          height: auto;
        }
      }
      .rank-select-wrap {
        margin-right: toMRem(10);
        /deep/ .el-radio-button__inner {
          width: toMRem(96);
          padding: 8px 6px !important;
          font-size: toMRem(12);
        }
      }
      .rank-beats {
        padding-top: toMRem(16);
        .rank-beats-tit {
        }
      }
      .businessUnit {
        h3 {
          font-size: toMRem(14);
        }
        ul {
          font-size: toMRem(12);
          padding-left: 16px;
          padding-top: 8px;
          li {
            list-style-type: disc;
            line-height: 2;
            font-weight: 100;
          }
        }
      }
    }
    .mob-visible {
      display: none !important;
    }
    .commonts-wrap {
      width: 100%;
      .chart-wrap {
        .chart-tit {
          img {
            width: toMRem(16);
            height: toMRem(16);
            margin-left: toMRem(20);
            cursor: pointer;
          }
        }
      }
      .item-wrap {
        width: 100%;
        .item {
          display: flex;
          justify-content: space-between;
          width: calc(50% - 18px);
          padding-bottom: toMRem(6);
          border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
          .right {
            min-width: toMRem(48);
            height: toMRem(48);
            border-radius: 50%;
            background: #86b1ba;
            line-height: toMRem(48);
            text-align: center;
            color: #ffffff;
            font-size: toMRem(30);
          }

          .bgGreen {
            background: #62b496;
          }
        }
      }
    }
  }
  .el-select-dropdown__item {
    max-width: toMRem(320);
  }
}
@media screen and (min-width: 761px) {
  .individual-wrap {
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding: toPad(0) toPad(18) toPad(100);
    margin-top: toPad(20);
    background: #f7f7f7;
    .header-bg {
      background: #fff;
      margin-bottom: toPad(24) !important;
    }
    .chart-wrap {
      position: relative;
      width: 100%;
      //   height: toPad(46);
      padding: toPad(16) toPad(18);
      background: #ffffff;
      box-shadow: 0px 4px 9px rgba(209, 209, 209, 0.25);
      border-radius: toPad(16);
      .chart-tit {
        img {
          width: toPad(16);
          height: toPad(16);
          margin-left: toPad(20);
          cursor: pointer;
        }
      }
      .round-wrap {
        align-items: center;
        width: 38%;
        border-right: 1px solid rgba(0, 0, 0, 0.05);
        padding: toPad(20) toPad(10);
        .round-one {
          position: relative;
          bottom: toPad(20);
          width: 35%;
          .round-text {
            //margin-right: toPad(14);
            display: inline-block;
            //text-align: center;
          }
          .round-num {
            display: inline-block;
            width: toPad(62);
            height: toPad(62);
            line-height: toPad(62);
            border-radius: 50%;
            color: #ffffff;
          }
        }
        .round-two {
          position: relative;
          top: toPad(40);
          vertical-align: top;
          text-align: center;
          width: 40%;
          margin-right: 8%;
          .round-text {
            display: inline-block;
            text-align: center;
            // width: 52%;
          }
          .round-num {
            display: inline-block;
            width: toPad(44);
            height: toPad(44);
            line-height: toPad(44);
            //margin-right: toPad(14);
            border-radius: 50%;
            color: #ffffff;
          }
        }
        .split-line {
          position: relative;
          left: toPad(10);
          bottom: toPad(10);
          width: toPad(1);
          height: toPad(70);
          mix-blend-mode: multiply;
          background: rgba(0, 0, 0, 0.2);
          transform: rotate(30deg);
        }
      }
      .number-wrap {
        width: 65%;
        padding: toPad(20) toPad(30);
        .number-item {
          display: flex;
          align-items: center;
          span {
            margin-left: toPad(10);
          }
        }
      }
      .select-wrap {
        height: toPad(60);
        .sel {
          width: 100%;
          height: 100%;
          color: #000;
        }
      }
      .score-header {
        margin-bottom: toPad(10);
      }
      .score-title {
        width: 100%;
        .title-item {
          width: 50%;
          padding: 0 toPad(4);
          align-items: center;
        }
        .score-line {
          width: 1px;
          height: toPad(34);
          background: rgba(0, 0, 0, 0.2);
        }
      }
      .chart-wraps {
        position: relative;
        .questionWordList-icon {
          z-index: 1;
          position: absolute;
          right: toPad(10);
          top: toPad(20);
          color: #439e69;
          font-size: toPad(18);
          cursor: pointer;
        }
        .score-chart-wrap {
          position: relative;
          width: 100%;
          height: toPad(400);
          box-sizing: content-box;
          margin: 0 auto;
          padding: 0 0 toRem(10) 0;
        }
      }
      .rank-title {
        padding: toPad(10) toPad(2);
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
        .rank-title-item {
          width: 46.5%;
          height: toPad(72);
          // span {
          //   width: 50%;
          // }
          .top-text-left {
            width: 65%;
          }
          .top-text-right {
            width: 35%;
          }
          .bot-text-left {
            width: 75%;
          }
          .bot-text-right {
            width: 25%;
          }
        }
        .rank-title-line {
          width: 1px;
          height: toPad(60);
          background: rgba(0, 0, 0, 0.15);
        }
        .fair-img {
          img {
            position: relative;
            bottom: toPad(6);
            display: inline-block;
            width: toPad(16);
            height: toPad(16);
            margin: 0 toPad(10);
          }
        }
        .fair-content {
          // width: toPad(240);
          left: 150%;
          height: auto;
        }
      }
      .rank-select-wrap {
        position: relative;
        bottom: toPad(20);
        float: right;
        display: flex;
        height: toPad(24);
        border-radius: toPad(24);
        border: 1px solid #d8e3db;
        width: toPad(248);
        overflow: hidden;
        .rank-select-item {
          width: toPad(124);
          height: toPad(24);
          line-height: toPad(24);
          text-align: center;
          color: #1e1e1e;
          &.active {
            color: #000;
            background: rgba(171, 211, 202, 0.62);
          }
        }
      }
      .rank-beats {
        //padding-top: toPad(16);

        .rank-beats-tit {
          display: flex;
          justify-content: center;
          //align-items: center;
          div {
            width: toPad(100);
            height: toPad(24);
          }
        }
      }
      .businessUnit {
        h3 {
          font-size: toPad(14);
        }
        ul {
          font-size: toPad(12);
          padding-left: 16px;
          padding-top: 8px;
          li {
            list-style-type: disc;
            line-height: 2;
            font-weight: 100;
          }
        }
      }
    }
    .pc-visible {
      display: none !important;
    }
    .row {
      display: flex;
      justify-content: space-between;
      .col-8 {
        width: calc(33% - 8px);
        .chart-wrap {
          width: 100%;
        }
      }
      .col-16 {
        width: calc(67% - 8px);
        .chart-wrap {
          width: 100%;
        }
      }
      .col-12 {
        width: calc(50% - 30px);
      }
    }
    .receivedAndGave {
      .chart-wrap {
        width: calc(50% - 8px);
      }
    }
    .commonts-wrap {
      width: 100%;
      .chart-wrap {
        .chart-tit {
          img {
            width: toPad(16);
            height: toPad(16);
            margin-left: toPad(20);
          }
        }
      }
      .item-wrap {
        width: 100%;
        .item {
          display: flex;
          justify-content: space-between;
          width: calc(50% - 20px);
          padding-bottom: toPad(16);
          border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
          .right {
            min-width: toPad(48);
            height: toPad(48);
            border-radius: 50%;
            background: #86b1ba;
            line-height: toPad(48);
            text-align: center;
            color: #ffffff;
            font-size: toPad(30);
          }

          .bgGreen {
            background: #62b496;
          }
        }
      }
    }
    .report-tips {
      line-height: toPad(12);
      img {
        width: toPad(16);
        height: toPad(16);
        margin-right: toPad(6);
      }
    }
  }
}
</style>
<style>
.el-input__inner {
  color: #000;
  border: 0;
}
.el-select .el-input .el-select__caret {
  color: #000;
}
.el-select-dropdown__item.selected {
  color: #000;
  font-weight: normal;
}
.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background-color: #fff;
}
.el-select-dropdown__item {
  color: #000;
}
input::-webkit-input-placeholder {
  text-overflow: inherit;
  line-height: initial;
  white-space: pre;
  overflow-wrap: wrap;
  -webkit-user-modify: read-only !important;
  overflow: visible;
}
i.el-select__caret {
  /*很关键：将默认的select选择框样式清除*/
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  /*为下拉小箭头留出一点位置，避免被文字覆盖*/
  padding-left: 24px;
  /*自定义图片*/
  background: url("../assets/member/select-arrow.png") no-repeat scroll right
    center transparent;
  /*自定义图片的大小*/
  background-size: 19px 14px;
}
/*将小箭头的样式去去掉*/
.el-icon-arrow-up:before {
  content: "";
}
.el-radio-button:first-child .el-radio-button__inner {
  border-radius: 24px 0 0 24px !important;
}
.el-radio-button:last-child .el-radio-button__inner {
  border-radius: 0 24px 24px 0;
}
.el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background-color: rgba(171, 211, 202, 0.62) !important;
  border-color: rgba(171, 211, 202, 0.62) !important;
  color: #060606;
}
</style>
<style>
.el-tooltip__popper[x-placement^="left"] .popper__arrow::after {
  border-left-color: #dff2f0 !important;
  border-right-color: #dff2f0 !important;
}

.el-tooltip__popper[x-placement^="left"] .popper__arrow {
  border-left-color: #dff2f0 !important;
  border-right-color: #dff2f0 !important;
}
.el-tooltip__popper[x-placement^="right"] .popper__arrow::after {
  border-left-color: #dff2f0 !important;
  border-right-color: #dff2f0 !important;
}

.el-tooltip__popper[x-placement^="right"] .popper__arrow {
  border-left-color: #dff2f0 !important;
  border-right-color: #dff2f0 !important;
}
.draw_share_atooltip {
  background: #dff2f0 !important;
  color: #000 !important;
}
</style>
